<template>
  <div class="view-register">
    <div class="header-box flex-c">
      <div class="logo-box flex-c">
        <div class="logo">
          <img class="w100" src="@/assets/images/pc/mb-logo.png" alt="" srcset="" />
        </div>
      </div>
    </div>
    <div class="center-box">
      <div class="input-item">
        <div class="title">
          Username
          <span style="color: red; vertical-align: sub"> *</span>
        </div>
        <div class="input-box">
          <el-input v-model="rsgFormData.username" placeholder="input"></el-input>
        </div>
      </div>
      <div class="input-item">
        <div class="title">
          Email
          <span style="color: red; vertical-align: sub"> *</span>
        </div>
        <div class="input-box">
          <el-input v-model="rsgFormData.email" placeholder="input"></el-input>
        </div>
      </div>
      <div class="input-item">
        <div class="title">
          Email verification code
          <span style="color: red; vertical-align: sub"> *</span>
        </div>
        <div class="input-box flex-s">
          <el-input v-model="rsgFormData.captcha" placeholder="input"></el-input>
          <div @click="sendEmailCode" class="send-btn flex-c point">
            {{ countdownTime > 0 ? `${countdownTime}S` : "Get Code " }}
          </div>
        </div>
      </div>
      <div class="input-item">
        <div class="title">
          Password
          <span style="color: red; vertical-align: sub"> *</span>
        </div>
        <div class="input-box">
          <el-input type="password" v-model="rsgFormData.password" placeholder="input"></el-input>
        </div>
      </div>
      <div class="input-item">
        <div class="title">
          Confirm password
          <span style="color: red; vertical-align: sub"> *</span>
        </div>
        <div class="input-box">
          <el-input type="password" v-model="rsgFormData.secondPassword" placeholder="input"></el-input>
        </div>
      </div>
      <div class="input-item">
        <div class="title">Authentication Code（Optional）</div>
        <div class="input-box">
          <el-input v-model="rsgFormData.authCode" placeholder="input"></el-input>
        </div>
      </div>
      <div class="tips">Certified members can enjoy preferential rates.</div>
      <div class="rsg-btn flex-c point" @click="userRegOnClick">SUBMIT</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import {
  userRsgApi,
  sendEmailCodeApi,
  checkEmailCodeApi,
} from "@/network/api";

import { validateEmail } from "@/utils/common.js";

export default {
  methods: {
    ...mapMutations([
      "setToken",
    ]),
    ...mapActions(['setTokenSync', "ReFreshUserInfo"]),
    async userRegOnClick() {
      if (!this.submitTimer) {
        return false;
      }
      this.submitTimer = false;
      setTimeout(() => {
        this.submitTimer = true;
      }, 3000);
      let flag = true;
      Object.keys(this.rsgFormData).forEach((key) => {
        if (key !== "authCode") {
          if (!this.rsgFormData[key]) {
            if (flag) {
              this.$message.error("Please fill in the complete information");
            }
            flag = false;
          }
        }
      });
      if (!flag) {
        return false;
      }
      if (this.rsgFormData.password !== this.rsgFormData.secondPassword) {
        this.$message.error("The two password entries are inconsistent");
        return false;
      }
      const isCheck = await this.checkEmailCode();
      if (!isCheck) {
        return false;
      }
      const params = this.rsgFormData;
      const res = await userRsgApi(params);
      await this.setTokenSync(res?.data?.userinfo?.token);
      this.$message.success(res.msg);
      this.$router.push("/");
    },
    async sendEmailCode() {
      if (this.countdownTime) {
        return false;
      }
      const email = this.rsgFormData.email;
      if (!validateEmail(email)) {
        this.$message.error("Email is not valid");
        return false;
      }
      const params = {
        email,
        event: "register",
      };
      if (this.Timer) {
        clearInterval(this.Timer);
      }
      const res = await sendEmailCodeApi(params);
      console.log(res);
      this.countdownTime = 60;
      this.Timer = setInterval(() => {
        if (this.countdownTime > 0) {
          this.countdownTime--;
        }
      }, 1000);
      this.$message.success(res.msg);
    },
    async checkEmailCode() {
      const params = {
        email: this.rsgFormData.email,
        event: "register",
        captcha: this.rsgFormData.captcha,
      };
      const res = await checkEmailCodeApi(params);
      return true;
    },
  },
  data() {
    return {
      rsgFormData: {
        username: "",
        password: "",
        secondPassword: "",
        email: "",
        authCode: "",
        captcha: "",
      },
      countdownTime: 0,
      Timer: null,
      submitTimer: true
    };
  },
  computed: {
    ...mapState([
      "isMinDevice",
    ]),
  },
};
</script>


<style lang="scss">
.view-register {
  width: 100%;
  min-height: 100vh;
  .header-box {
    width: 100%;
    height: 14.4393rem;
    @include pcurl("mb-login-icon1.png");
    margin-top: -1.0753rem;
    .logo-box {
      .logo {
        width: 13.0616rem;
      }
    }
  }
  .center-box {
    padding: 0 1.5873rem;
    margin-top: -2.9698rem;
    .input-item {
      margin-bottom: 0.12rem;

      .title {
        font-size: 0.7168rem;
        margin-bottom: 0.3072rem;
        color: rgba(147, 147, 147, 1);
      }
      .input-box {
        width: 100%;
        height: 1.6385rem;
        margin-bottom: 0.6144rem;
        .send-btn {
          //   width: 2.04rem;
          padding: 0.3584rem 0.5632rem;
          //   height: 1.6385rem;
          background: rgba(0, 56, 121, 1);
          border-radius: 0.04rem;
          font-size: 0.7168rem;
          color: #fff;
          flex-shrink: 0;
        }
      }
    }

    .tips {
      font-size: 0.7168rem;
      color: #888;
      margin-bottom: 1.8945rem;
    }

    .rsg-btn {
      width: 100%;
      height: 2.0481rem;
      background: rgba(0, 56, 121, 1);
      color: #fff;
      font-size: 0.8193rem;
      margin-bottom: 0.12rem;
    }
  }
}
</style>